<template>
  <section id="hero">
    <base-jumbotron
      :src="require('@/assets/bg.jpg')"
      color="grey darken-4"
      dark
      height="100%"
      min-height="100vh"
    >
      <v-row
        align="center"
        class="pb-12"
      >
        <v-col align="center">
          <div>
            <base-title>
              James Morse
            </base-title>

            <v-divider class="mb-3" />

            <div class="subheading mb-4" />
            <v-btn
              v-for="([href, text], i) in socials"
              :key="`social-${i}`"
              :href="href"
              class="px-0 mx-2"
              color="grey darken-1"
              dark
              min-width="48"
              rel="noopener"
              target="_blank"
            >
              <v-icon
                size="18"
                v-text="text"
              />
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </base-jumbotron>
  </section>
</template>

<script>
  export default {
    name: 'HomeHero',

    data: () => ({
      socials: [
        ['https://github.com/dtdas', 'mdi-git'],
        ['https://www.linkedin.com/in/james-morse-5a4a4095/', 'mdi-linkedin'],
      ],
    }),
  }
</script>
